import styled from 'styled-components';
import { themeGet } from 'styled-system';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .reusecore__button {
    color: ${themeGet('colors.almostWhite')};
  }
  
  .hamburgMenu__bar {
    span {
      background-color: ${themeGet('colors.almostWhite')};
    }
  }

  .flaticon-magnifying-glass {
    color: ${themeGet('colors.almostWhite')};
  }

  .flaticon-user {
    color: ${themeGet('colors.almostWhite')};
  }
`;

export { Container };
