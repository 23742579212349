const colors = {
  transparent: 'transparent',
  label: '#C6C6C6',
  lightBorder: '#red',
  inactiveField: '#f2f2f2',
  inactiveButton: '#b7dbdd',
  inactiveIcon: '#EBEBEB',
  primaryHover: '#EDE10F',
  secondary: '#D50032',
  secondaryHover: '#EE2541',
  yellow: '#ffcc17',
  yellowHover: '#ffec37',
  purple: '#eb007a',
  purpleHover: '#fb209a',
  border: '#dadada',
  black: '#000000',
  white: '#ffffff',
  almostWhite: '#e2e2e2',
  primary: '#0edd85',
  heading: '#292929',
  heading3: '#f9641f',
  quote: '#343d48',
  text: '#4E5865',
  lightText: '#7E7E7E',
  link: '#ed0187',
  banner: '#ffffff',
  darkBg: '#0D0D0D',
  lightBg: '#ffffff',
  paletteBlack: '#000000',
  paletteBlue: '#0057ff',
  paletteOrange: '#fa6520',
  paletteGreen: '#0edd85',
  palettePurple: '#ee0188',
  paletteYellow: '#ffcc18',
  paletteRed: '#f02333',
  paletteLightBlue: '#00abff',
  paletteMagenta: '#7722cc',
};

export default colors;
