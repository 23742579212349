import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/fa/facebook';
import { twitter } from 'react-icons-kit/fa/twitter';
import { instagram } from 'react-icons-kit/fa/instagram';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Logo from 'reusecore/src/elements/UI/Logo';
import Container from 'common/src/components/UI/Container';
import FooterWrapper, {
  List,
  ListItem,
  SocialList,
} from './footer.style';

const socialLinks = [
  {
    id: 1,
    icon: <Icon icon={twitter} />,
    name: 'twitter',
    link: 'https://twitter.com/mandasalve/',
  },
  {
    id: 2,
    icon: <Icon icon={instagram} />,
    name: 'instagram',
    link: 'https://www.instagram.com/mandasalve/',
  },
  {
    id: 3,
    icon: <Icon icon={facebook} />,
    name: 'facebook',
    link: 'https://www.facebook.com/Manda-Salve-102112781216748/',
  },
];

const Footer = ({ row, col, colOne, colTwo }) => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        footerData {
          logo {
            publicURL
          }
          mail
          menuWidgets {
            id
            title
            menu {
              id
              text
              link
            }
          }
        }
      }
    }
  `);
  const { logo, mail, menuWidgets } = data.interiorJson.footerData;

  return (
    <Fragment>
      <FooterWrapper>
        <Container width="1330px">
          <Box className="row" {...row}>
            <Box className="col-one" {...colOne}>
              <Logo
                className="logo"
                href="/interior"
                logoSrc={logo.publicURL}
                title="Interior"
              />
              <Text className="text" content={<a href={`mailto:${mail}`}>{mail}</a>} />
              <SocialList>
                {socialLinks.map(item => (
                  <li className={item.name} key={`link-key${item.id}`}>
                    <a aria-label={item.name} href={item.link}>
                      {item.icon}
                    </a>
                  </li>
                ))}
              </SocialList>
            </Box>
            {/* End of logo column */}

            <Box className="col-two" {...colTwo}>
              {menuWidgets.map(widget => (
                <Box
                  className="col"
                  {...col}
                  key={`footer_widget-key${widget.id}`}
                >
                  <Heading
                    className="widget_title"
                    as="h3"
                    content={widget.title}
                  />
                  <List>
                    {widget.menu.map(item => (
                      <ListItem key={`list__item-${item.id}`}>
                        <a href={item.link}>{item.text}</a>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
            </Box>
            {/* End of List column */}
          </Box>
          {/* End of widgets row */}
          <Box className="row copyright" {...row}>
            <Text className="text" dangerouslySetInnerHTML={{__html:`Manda Salve Negócios Digitais LTDA<br />CNPJ: 35.508.177/0001-88<br />R 128, Número 67, Quadra F29, Lote 5, Sala 2, Goiânia/GO`}} />
          </Box>
          {/* End of copyright row */}
        </Container>
      </FooterWrapper>
    </Fragment>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '35%', '28%'],
    mt: [0, '13px', '0'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '65%', '72%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default Footer;
